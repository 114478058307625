<template>
  <div id="pdf"></div>
</template>

<script>
export default {
  name: "PDFView",
  props: ["url"],

  mounted() {
    setTimeout(() => {
      PDFObject.embed(this.url, "#pdf");
    }, 2000);
  }
};
</script>

<style scoped>
.pdfobject-container {
  height: 70vh;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
</style>
