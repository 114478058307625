<template>
  <div class="container1">
    <Header active_tab="mentorship"></Header>
    <div class="essay-upload-container">
      <div class="container" v-if="!user.data.admin">
        <form class="form" @submit.prevent="upload">
          <div class="uk-form-controls">
            <label for="title">Enter title</label>
            <input id="title" v-model="title" />
          </div>
          <div class="file-upload-wrapper" :data-text="selectedFileName">
            <input
              name="file-upload-field"
              type="file"
              class="file-upload-field"
              value=""
              accept="application/pdf,application/msword"
              @change="onFileChanged"
            />
            <button class="btn btn-success upload">Upload</button>
          </div>
        </form>
        <EssayListItem
          v-for="(item, index) in essayList"
          :key="index"
          :from="item.title"
          :review="item.review"
          :is-reviewed="item.reviewed"
          :index="index"
          style="margin-top: 20px"
          :img="img"
          @downloadExcel="download(item.url)"
          @downloadAdmin="download(item.Adminurl)"
        ></EssayListItem>
      </div>

      <div v-else class="admin">
        <h1 class="admin-title">{{ title }}</h1>
        <div class="pdf-container">
          <PDFView :url="url"></PDFView>
        </div>
        <div class=" review-container" style="display: flex">
          <label for="review">Enter Review</label>
          <input id="review" v-model="review" />

          <div class="file-upload-wrapper" :data-text="selectedFileName">
            <input
              name="file-upload-field"
              type="file"
              class="file-upload-field"
              value=""
              ref="myFiles"
              accept="application/pdf,application/msword"
              id="admin-file"
              @change="onFileChanged"
            />
            <button
              class="btn btn-success upload-admin"
              @click="reviewNotification"
            >
              Upload and Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import store from "@/store";
import firebase from "firebase";
import db from "@/firebase";
import { mapGetters } from "vuex";
import EssayListItem from "@/components/EssayListItem";
import PDFView from "@/components/PDFView";

export default {
  name: "Essay",
  components: { EssayListItem, Header, PDFView },
  async mounted() {
    if (this.user.data.admin) {
      this.title = this.$route.query.title;
      this.url = this.$route.query.url;
      this.uid = this.$route.query.uid;
      this.milliseconds=this.$route.query.milliseconds;
      this.fileName = this.$route.query.fileName;
    } else {
      this.essayList = (
        await db
          .collection("essay")
          .doc(store.getters.user.data.uid)
          .get()
      )
        .data()
        .essay.reverse();
    }

    // console.log(esssay);
    // this.essayList = esssay.essay;
    // console.log(this.essayList);

    store.state.loading = false;
  },
  methods: {
    onFileChanged(e) {
      if (this.user.data.admin) this.adminFile = this.$refs.myFiles.files;
      if (e.srcElement.files[0]) {
        this.selectedFileName = e.srcElement.files[0].name;
      } else {
        this.selectedFileName = "";
      }
      console.log(e.srcElement.files[0]);
    },
    upload(e) {
      if (this.title.length == 0) {
        swal("Enter Title of essay");
        return;
      }
      if (!e.target[1].files[0]) {
        swal("Select a pdf or docs");
        return;
      }

      store.state.loading = true;

      let file = e.target[1].files[0];
      let milliseconds = new Date().getTime();

      firebase
        .storage()
        .ref(
          "essay/" +
            store.getters.user.data.uid +
            `/${milliseconds}.${this.getFileExtension(this.selectedFileName)}`
        )
        .put(file)
        .then(snapshot => {
          //Call our server to do some work
          snapshot.ref.getDownloadURL().then(url => {
            console.log(url);

            var essayDetail = [
              {
                title: this.title,
                fileName: milliseconds,
                url: url,
                reviewed: false,
                review: ""
              }
            ];

            db.collection("essay")
              .doc(store.getters.user.data.uid)
              .set(
                {
                  essay: firebase.firestore.FieldValue.arrayUnion(
                    ...essayDetail
                  )
                },
                {
                  merge: true
                }
              );

            
            // e.target[1].files[0] = null;
            this.selectedFileName = "";
            store.state.loading = false;
            this.putNotification({
              url: url,
              fileName: milliseconds,
              time: Date(),
              title: this.title
            },);
            this.title = "";
          });
        });
    },

    getFileExtension(filename) {
      return filename.split(".").pop();
    },
    putNotification(data) {
      
      let uid = store.getters.user.data.uid;
      let milliseconds = new Date().getTime();
      milliseconds=milliseconds.toString();
      let name = {
        message: `${store.getters.user.data.username} uploaded an essay`,
        uid: uid,
        milliseconds:milliseconds
      };
      db.collection("essay_upload_notification")
        .doc(uid+milliseconds)
        .set({ ...data, ...name })
        .then(res => {
          location.reload();
        });
    },
    async reviewNotification() {
      if (Object.keys(this.adminFile).length === 0) {
        swal({
          title: "Pdf Not selected",
          text: "You havent selected any pdf,Continue?",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(upload => {
          if (!upload) {
            return;
          }
        });
      }

      let timestamp = Date.now();

      console.log(this.adminFile);
      console.log(
        ` admin${timestamp}.${this.getFileExtension(this.adminFile[0].name)}`
      );
      firebase
        .storage()
        .ref(
          "essay/" +
            this.uid +
            `/admin${timestamp}.${this.getFileExtension(
              this.adminFile[0].name
            )}`
        )
        .put(this.adminFile[0])
        .then(snapshot => {
          store.state.loading = true;
          snapshot.ref.getDownloadURL().then(url => {
            for (let i = 0; i < data["essay"].length; i++) {
              if (data.essay[i].fileName == this.fileName) {
                data.essay[i].reviewed = true;
                data.essay[i].review = this.review;
                data.essay[i].Adminurl = url;
                break;
              }
            }

            db.collection("essay")
              .doc(this.uid)
              .set(data)
              .then(() => {
                db.collection("essay_upload_notification")
                  .doc(this.uid+this.milliseconds)
                  .delete()
                  .then(() => {
                    store.state.loading = false;
                    this.$router.push({
                      name: "Schedule"
                    });
                  });
              });
          });
        })
        .catch(err => {
          console.log(err);
        });

      var data = (
        await db
          .collection("essay")
          .doc(this.uid)
          .get()
      ).data();
      console.log(data);
    },
    download(url) {
      window.open(url);
    }
  },
  data() {
    return {
      selectedFileName: "Select your file!",
      title: "",
      url: "",
      review: "",
      fileName: "",
      uid: "",
      essayList: [],

      img: require("/src/assets/download.svg"),
      adminFile: []
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped>
.essay-upload-container {
  padding-top: 0;
}

.container1 {
  background-image: linear-gradient(to right, red,5%,rgb(255, 220, 220),90%,red);
  height: 100vh;
}

.form {
  width: 800px;
  margin-top: 50px;
  display: flex;
}

.file-upload-wrapper {
  position: relative;
  width: 50%;
  height: 62px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  /* background: #fff; */
  padding: 10px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: 40px;
  line-height: 40px;
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
}

.file-upload-wrapper:before {
}

.file-upload-wrapper:hover:before {
  background: #3d8c63;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#title,
#review {
  padding-left: 10px;
  margin-left: 20px;
  height: 62px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  margin-right: 30px;
  color: black;
  font-family: "Nunito", "Helvetica", sans-serif;
}
#title {
  background: transparent;
}

.review-container {
  width: 400px;

  margin: 20px auto;
}

#title:focus-visible {
  outline: none;
}

.upload {
  z-index: 20;
  height: 62px;
  border-top-left-radius: 0;
  float: end;
  right: 0;
  margin-left: 400px;
}

.upload-admin {
  z-index: 20;
  height: 62px;
  border-top-left-radius: 0;
  float: end;
  right: 0;
  margin-left: 220px;
}

.admin-title {
  margin: 0 auto;
  text-align: center;
}
</style>
